<template>
    <v-container>
        <RequireLogin>
            <div v-show="$vuetify.breakpoint.smAndUp">
                <div class="note-list">
                    <v-row class="py-5 px-5">
                        <v-card class="ma-0 pa-0"  min-width="300" max-width="300">
                            <v-toolbar short flat color="white">
                                <v-toolbar-title class="purple--text">My Notes</v-toolbar-title>
                                <v-spacer></v-spacer>
                                <v-btn icon color="purple" @click="createNote">
                                    <font-awesome-icon icon="plus" style="font-size: 20px;" fixed-width/>
                                </v-btn>
                            </v-toolbar>
                            <v-divider></v-divider>
                            <v-list dense nav>
                                <v-list-item v-if="list.length === 0">You don't have any notes yet.</v-list-item>
                                <v-list-item v-for="item in list" :key="item.id" @click="selectNote(item.id)" link>
                                    <v-list-item-content>
                                        <v-list-item-title class="text-subtitle-1">{{ item.info.title }}</v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-list>
                        </v-card>
                    </v-row>
                </div>
                <div class="note-content">
                    <v-row class="py-5 px-2">
                        <v-col class="pa-0">
                            <v-form v-model="editNoteForm" ref="editNoteFormRef" @submit="editNote" onSubmit="return false;" @keyup.enter.native="editNote">
                                <v-text-field
                                    v-model=noteTitle
                                    placeholder="Title"
                                    color="purple"
                                    autofocus
                                    style="font-size: 24px"
                                    @keyup="editPending()"
                                    @blur="editNote()"
                                >
                                </v-text-field>
                                <v-textarea
                                    ref="contentInputRef"
                                    v-model=noteContent
                                    placeholder="Type the note here"
                                    color="purple"
                                    type="text"
                                    auto-grow
                                    @keyup="editPending()"
                                    @blur="editNote()"
                                >
                                </v-textarea>
                                <v-row class="no-print pl-3 pr-2">
                                    <div>
                                        <p class="text-caption grey--text" v-show="isEditPending">You have unsaved changes</p>
                                        <p class="text-caption grey--text" v-show="!isEditPending">Saved</p>
                                    </div>
                                    <v-spacer/>
                                    <v-btn icon color="red" @click="deleteNote" v-if="noteId">
                                        <font-awesome-icon icon="trash" style="font-size: 20px;" fixed-width/>
                                    </v-btn>
                                </v-row>
                            </v-form>
                        </v-col>
                    </v-row>
                </div>
            </div>

            <div v-show="$vuetify.breakpoint.xsOnly">
                <div v-show="!isNoteSelected">
                    <v-row class="py-5">
                        <v-col class="pa-0">
                            <v-card class="ma-0 pa-0">
                                <v-toolbar short flat color="white">
                                    <v-toolbar-title class="purple--text">My Notes</v-toolbar-title>
                                    <v-spacer></v-spacer>
                                    <v-btn icon color="purple" @click="createNote">
                                        <font-awesome-icon icon="plus" style="font-size: 20px;" fixed-width/>
                                    </v-btn>
                                </v-toolbar>
                                <v-divider></v-divider>
                                <v-list dense nav>
                                    <v-list-item v-if="list.length === 0">You don't have any notes yet.</v-list-item>
                                    <v-list-item v-for="item in list" :key="item.id" @click="selectNote(item.id)" link>
                                        <v-list-item-content>
                                            <v-list-item-title class="text-subtitle-1">{{ item.info.title }}</v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-list>
                            </v-card>
                        </v-col>
                    </v-row>
                </div>
                <div v-show="isNoteSelected">
                    <v-row class="py-2">
                        <v-btn icon color="purple" @click="backToList()">
                            <font-awesome-icon icon="arrow-left" style="font-size: 20px;" fixed-width/>
                        </v-btn>
                    </v-row>
                    <v-row class="px-2">
                        <v-col>
                            <v-form v-model="editNoteForm" ref="editNoteFormRef" @submit="editNote" onSubmit="return false;" @keyup.enter.native="editNote">
                                <v-text-field
                                    v-model=noteTitle
                                    placeholder="Title"
                                    color="purple"
                                    autofocus
                                    style="font-size: 24px"
                                    @keyup="editPending()"
                                    @blur="editNote()"
                                >
                                </v-text-field>
                                <v-textarea
                                    ref="contentInputRef"
                                    v-model=noteContent
                                    placeholder="Type the note here"
                                    color="purple"
                                    type="text"
                                    auto-grow
                                    @keyup="editPending()"
                                    @blur="editNote()"
                                >
                                </v-textarea>
                                <v-row class="no-print pl-3 pr-2">
                                    <div>
                                        <p class="text-caption grey--text" v-show="isEditPending">You have unsaved changes</p>
                                        <p class="text-caption grey--text" v-show="!isEditPending">Saved</p>
                                    </div>
                                    <v-spacer/>
                                    <v-btn icon color="red" @click="deleteNote" v-if="noteId">
                                        <font-awesome-icon icon="trash" style="font-size: 20px;" fixed-width/>
                                    </v-btn>
                                </v-row>
                            </v-form>
                        </v-col>
                    </v-row>
                </div>
            </div>
        </RequireLogin>
    </v-container>
</template>

<style>
@media print {
    .no-print, .note-list {
        display: none !important;
    }
}
@media screen {
    .note-list {
        position: absolute;
        left: 0px;
        width: 300px;
    }
    .note-content {
        margin-left: 300px;
    }
}
</style>
<script>
import { mapState /* , mapGetters */ } from 'vuex';
import { Client } from '@/client';
import RequireLogin from '@/components/RequireLogin.vue';

const client = new Client();

export default {
    components: {
        RequireLogin,
    },
    data() {
        return {
            // current note being edited
            noteId: null,
            noteContent: null,
            noteTitle: null,
            isEditPending: false,
            // list of notes
            list: [], // each item { id, content, info: {title} }
            editNoteForm: null,
            isNoteSelected: false,
        };
    },
    methods: {
        init() {
            console.log('note.vue: init; isAuthenticated: %o', this.isAuthenticated);
            if (this.noteId) {
                this.selectNote(this.noteId);
            }
            this.fetchNoteList();
            // this.$store.dispatch('fetchNoteList');
        },
        editPending() {
            console.log('note.vue: editPending called');
            this.isEditPending = true;
        },
        async fetchNoteList() {
            const { list } = await client.note.list();
            this.list = list;
        },
        createNote() {
            // clear the note section; new note will be created automatically when user types anything
            this.noteId = null;
            this.noteTitle = null;
            this.noteContent = null;
            this.isNoteSelected = true;
        },
        async editNote() {
            const isNotEmpty = (typeof this.noteTitle === 'string' && this.noteTitle.trim().length > 0) || (typeof this.noteContent === 'string' && this.noteContent.trim().length > 0);
            // ensure there is a unique title
            if (typeof this.noteTitle !== 'string' || this.noteTitle.trim().length === 0) {
                this.noteTitle = `Untitled ${Math.random()}`; // TODO: look for next unused 'untitled' number
            }
            const title = this.noteTitle;

            if (this.noteId) {
                const { isEdited } = await client.note.edit(this.noteId, { content: this.noteContent, info: { title } });
                console.log(`note.vue: edited note: ${isEdited}`);
                if (isEdited) {
                    this.isEditPending = false;
                }
                // update title in list
                const found = this.list.filter((item) => item.id === this.noteId);
                if (found.length === 1) {
                    found[0].info.title = title;
                }
            } else if (isNotEmpty) {
                const { isCreated, id } = await client.note.create({ content: this.noteContent, info: { title } });
                console.log(`note.vue: created note: ${isCreated} id ${id}`);
                if (isCreated) {
                    this.isEditPending = false;
                }
                this.noteId = id;
                // add to list
                this.list.push({ id, content: this.noteContent, info: { title } });
            }
        },
        async selectNote(noteId) {
            const { id, content, info: { title } } = await client.note.fetch({ id: noteId });
            console.log(`note.vue: fetched note: id ${id}`);
            this.noteId = id;
            this.noteTitle = title;
            this.noteContent = content;
            this.isNoteSelected = true;
        },
        async deleteNote() {
            if (this.noteId) {
                const { isDeleted } = await client.note.delete(this.noteId);
                console.log(`note.vue: deleted note: ${isDeleted}`);
                if (isDeleted) {
                    // remove from list
                    let found = -1;
                    for (let i = 0; i < this.list.length; i += 1) {
                        if (this.list[i].id === this.noteId) {
                            found = i;
                            break;
                        }
                    }
                    if (found > -1) {
                        this.list.splice(found, 1);
                    }
                    // clear the editing area
                    this.noteId = null;
                    this.noteTitle = null;
                    this.noteContent = null;
                    this.isEditPending = false;
                    this.isNoteSelected = false;
                }
            }
        },
        backToList() {
            this.isNoteSelected = false;
        },
    },
    computed: {
        ...mapState({
            isReady: (state) => state.isReady,
            session: (state) => state.session,
            // noteList: (state) => state.noteList,
        }),
        isAuthenticated() {
            return this.session.isAuthenticated;
        },
        // list() {
        //     return this.noteList;
        // },
    },
    watch: {
        isReady(value, oldValue) {
            console.log('note.vue: isReady changed from %o to %o', oldValue, value);
            // only call init again if ready changed from false to true after mounted()
            if (value && !oldValue) {
                this.init();
            }
        },
    },
    mounted() {
        console.log('note.vue: mounted');
        if (this.isReady) {
            this.init();
        }
    },
    created() {
        this.noteId = this.$route.query.noteId;
        console.log(`note.vue: created, noteId: ${this.noteId}`);
    },
};
</script>
